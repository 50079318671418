<template>
    <td
        class="table-cell"
        :class="[config.type, className, {'slim': slim}, {'word-break': config.type === 'truncate'}]"
        :style="{'text-align': config.align}">
        <template v-if="config.type === 'component'">
            <component
                :is="config.component"
                :item="item"
                v-bind="cellProps"
                @set-class="setClass"
                @callback-with-data="callbackWithData"
                @callback="callFunction" />
        </template>
        <template v-else-if="config.type === 'date'">
            {{ formatDate(getValue(item)) }}
        </template>
        <template v-else-if="config.type === 'date-from'">
            {{ fromNow(getValue(item)) }}
        </template>
        <template v-else-if="config.type === 'empty'" />
        <template v-else-if="config.type === 'stacked'">
            <div class="stacked-content">
                <div class="top">
                    {{ getValue(item, 'top') }}
                </div>
                <div class="bottom">
                    {{ getValue(item, 'bottom') }}
                </div>
            </div>
        </template>
        <template v-else-if="config.type === 'truncate'">
            <template v-if="getValue(item).length > 35">
                <truncate-text
                    :value="getValue(item)"
                    :characters="35" />
            </template>
            <template v-else>
                {{ getValue(item) }}
            </template>
        </template>
        <template v-else>
            {{ getValue(item) }}
        </template>
    </td>
</template>
<script>
import { get } from 'lodash';
import { format } from 'date-fns';
import fromNow from '@/helpers/fromNow';
import TruncateText from '@/components/globals/TruncateText';

export default {
    components: {
        TruncateText
    },
    props: {
        config: {
            type: Object,
            default: () => {},
        },
        item: {
            type: Object,
            default: () => {},
        },
        slim: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            className: '',
            fromNow
        };
    },
    computed: {
        cellProps() {
            // Ensure that the configuration is setup correctly
            if ( this.config.type !== 'component' && (typeof this.config.props !== 'function' || typeof this.config.props !== 'object')) {
                return {};
            }

            // If the user has passed the props as a function call it with the
            // item and config for this cell so the component can decide how to
            // handle the data
            if (typeof this.config.props == 'function') {
                return this.config.props.call(null, this.item, this.getValue(this.item));
            }

            // Fall back to allowing a basic object
            return this.config.props;
        },
    },
    methods: {
        setClass(className) {
            this.className = className;
        },
        callFunction() {
            this.config.callback(this.item);
        },
        callbackWithData(data) {
            this.config.callback(data);
        },
        getValue(object, type) {
            const stringValuePath = this.config.value[type] ?? this.config.value;
            const convertedPath = get(object, stringValuePath);
            if (convertedPath) return convertedPath;
            else return '--';
        },
        formatDate(date) {
            return format(date, 'MM/DD/YYYY');
        },
    }
};
</script>

<style lang="scss" scoped>
.table-cell {
    padding: 18px 15px;
    font-size: 13px;
}
.word-break {
    word-break: break-all;
}
.table-cell.slim {
    padding: 10px 15px;
    position: relative;
}
.stacked {
    .stacked-content{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .top {
            border-bottom: 1px solid #989898;
            padding-bottom: 5px;
        }
        .bottom{
            padding-top: 5px;
            transform: translateY(-1px);
            border-top: 1px solid #989898;
        }
    }
}
</style>
