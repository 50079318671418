<template>
    <div class="custom-alert-container">
        <v-alert
            :style="{
                height: '40px',
                border: '0px',
                margin: '0px',
                'text-align': 'center',
                color: color.text
            }"
            :class="{info: alert.type == 'info'}"
            :value="showAlert"
            :color="color.bg"
            close-text="Close Alert"
            dense
            dismissible
            @input="closeAlert">
            {{ alert.message }}
            <span
                v-if="loading && alert.message == ''"
                class="notification-loading">
                LOADING
                <loading-icon />
            </span>
            <template>
                <slot name="selectedRows" />
            </template>
        </v-alert>
    </div>
</template>

<script>
import LoadingIcon from '@/components/globals/CustomTable/elements/LoadingIcon.vue';

export default {
    name: 'CustomAlertBar',
    components: { LoadingIcon },
    props: {
        showAlert: {
            type: Boolean,
            default: false,
        },
        alert: {
            type: Object,
            default: () => {},
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        color() {
            if (this.alert.type == 'error') {
                return {
                    text: '#000000',
                    bg: '#F5C2BB'
                };
            }
            if (this.alert.type == 'warning') {
                return {
                    text: '#000000',
                    bg: '#F9E0B7'
                };
            }
            return {
                text: '#ffffff',
                bg: '#03A2EA'
            };
        },
    },
    methods: {
        closeAlert(value) {
            if (value == false) {
                this.$emit('close');
            }
        }
    }
};
</script>

<style lang="scss">
.custom-alert-container {
    box-shadow: 0 9px 7px -7px rgba(0,0,0,0.25);
    position: relative;
    overflow: hidden;
    height: auto !important;
    .v-alert > {
        .v-alert__dismissible {
            margin-top: -7px;
        }
    }
    .v-alert:not(.info) .v-alert__dismissible .v-icon {
        color: #8F9EA6;
    }
    .v-alert .v-alert__dismissible .v-icon {
        color: $alto;
    }
    .v-alert a {
        font-weight: 700;
    }
    .v-alert.warning a,
    .v-alert.error a{
        color: #02B0E3;
    }
    .notification-loading{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
}
.loading-bar-icon {
    padding: 10px;
    margin: 60px;
}
</style>
