var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-alert-container"},[_c('v-alert',{class:{info: _vm.alert.type == 'info'},style:({
            height: '40px',
            border: '0px',
            margin: '0px',
            'text-align': 'center',
            color: _vm.color.text
        }),attrs:{"value":_vm.showAlert,"color":_vm.color.bg,"close-text":"Close Alert","dense":"","dismissible":""},on:{"input":_vm.closeAlert}},[_vm._v(" "+_vm._s(_vm.alert.message)+" "),(_vm.loading && _vm.alert.message == '')?_c('span',{staticClass:"notification-loading"},[_vm._v(" LOADING "),_c('loading-icon')],1):_vm._e(),[_vm._t("selectedRows")]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }