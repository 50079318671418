<template>
    <div class="filter-row-container table-header">
        <div class="sorting-fields">
            <div
                v-if="actions.length"
                class="field bulk-actions">
                <v-select
                    v-model="selectedAction"
                    :items="actions"
                    item-text="label"
                    item-value="label"
                    label="Bulk Actions"
                    class="styled-field"
                    :item-disabled="disableItem"
                    return-object />
            </div>
            <div
                v-if="!hideSearch"
                class="field search">
                <v-text-field
                    v-model="searchString"
                    prepend-inner-icon="search"
                    type="string"
                    :label="searchLabel"
                    class="styled-field has-icon"
                    clearable />
            </div>
            <div
                v-if="!hideSort"
                class="field sort-by">
                <v-select
                    :value="pagination.sortBy"
                    :items="sortByData"
                    item-text="display_name"
                    item-value="id"
                    return-object
                    label="Sort by"
                    class="styled-field"
                    clearable
                    @change="setSorting" />
            </div>
            <div>
                <slot />
            </div>
        </div>
        <div
            v-if="hasCustomization"
            class="column-selection">
            <div class="field column-presets">
                <v-select
                    v-model="columnPresetSelected"
                    :items="joinedPresets"
                    item-text="name"
                    item-value="id"
                    prefix="Column Presets:"
                    class="styled-field"
                    :disabled="loading"
                    :loading="loading"
                    clearable
                    return-object
                    @change="saveSelectedPreset" />
            </div>
            <button
                class="edit-columns"
                @click="editColumnsPreset">
                <styled-tooltip
                    v-model="showDeleteForm"
                    content-class="preset-name-tooltip"
                    open-on-click>
                    <template #content>
                        <div class="new-preset-form">
                            <v-text-field
                                v-model="columnPresetName"
                                class="styled-field mb-3"
                                outlined
                                dense
                                disabled
                                :rules="nameRules"
                                name="name"
                                label="Preset name"
                                required />
                            <div class="delete-text">
                                Are you sure you want to delete this preset?<br>
                            </div>
                            <div class="delete-buttons">
                                <styled-button
                                    small
                                    :loading="loading"
                                    :disabled="loading"
                                    @click.prevent="deletePreset">
                                    YES DELETE
                                </styled-button>
                                <div
                                    class="cancel"
                                    @click.stop="goBack">
                                    Cancel
                                </div>
                            </div>
                        </div>
                    </template>
                </styled-tooltip>
                <styled-tooltip
                    v-model="showPresetNameForm"
                    content-class="preset-name-tooltip"
                    open-on-click>
                    <template #content>
                        <div class="new-preset-form">
                            <div class="customize-text">
                                Name your custom preset
                            </div>
                            <v-text-field
                                v-model="columnPresetName"
                                class="styled-field mb-3"
                                outlined
                                dense
                                :rules="nameRules"
                                name="name"
                                label="Preset name"
                                required />
                            <div class="shared-checkbox">
                                <v-checkbox
                                    v-model="sharedSelected"
                                    label="Share with my organization"
                                    color="primary"
                                    class="styled-checkbox" />
                            </div>
                            <div class="new-preset-buttons">
                                <styled-button
                                    small
                                    gray
                                    light
                                    @click.stop="goBack">
                                    BACK
                                </styled-button>
                                <styled-button
                                    small
                                    :loading="loading"
                                    :disabled="columnPresetName == null || columnPresetName.length === 0 || loading"
                                    @click.prevent="saveColumnPreset">
                                    OK
                                </styled-button>
                            </div>
                        </div>
                    </template>
                </styled-tooltip>
                <styled-tooltip
                    v-model="showPresetColumnSelector"
                    position="left"
                    :max-width="450"
                    :high-index="true"
                    :custom-offset="tooltipPosition"
                    open-on-click>
                    <template #content>
                        <div class="customize-text">
                            Customize Columns
                        </div>
                        <div class="basket-holder">
                            <v-text-field
                                v-if="columnPresetSelected"
                                v-model="columnPresetName"
                                class="styled-field mb-3"
                                outlined
                                dense
                                :rules="nameRules"
                                name="name"
                                label="Preset name"
                                required />
                            <button
                                v-if="columnPresetSelected"
                                @click.stop="showDeleteConfirmation">
                                <icon
                                    size="25"
                                    name="trash" />
                            </button>
                        </div>
                        <div class="draggable-holder">
                            <draggable
                                v-model="sortableHeaders"
                                @end="onDraggingEnd">
                                <div
                                    v-for="header in sortableHeaders"
                                    :key="header.id"
                                    class="column-selector">
                                    <div
                                        v-if="!header.nonEditableColumn"
                                        class="column-selector-holder">
                                        <div class="dots">
                                            <div />
                                            <div />
                                            <div />
                                            <div />
                                            <div />
                                            <div />
                                        </div>
                                        <v-switch
                                            v-model="header.active"
                                            color="primary"
                                            @change="checkSwitch" />
                                        <span>{{ formatName(header.header) }}</span>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        <div class="actions">
                            <template v-if="columnPresetSelected">
                                <styled-button
                                    class="custom-styled-btn"
                                    small
                                    @click.stop="setColumns">
                                    SAVE NEW
                                </styled-button>
                                <styled-button
                                    small
                                    :loading="loading"
                                    :disabled="loading"
                                    @click.stop="reviseColumnPreset">
                                    UPDATE EXISTING
                                </styled-button>
                            </template>
                            <template v-else>
                                <styled-button
                                    small
                                    @click.stop="setColumns">
                                    SAVE AS CUSTOM PRESET
                                </styled-button>
                            </template>
                        </div>
                    </template>
                    <button
                        ref="editButton"
                        class="edit-columns-button">
                        <icon
                            size="20"
                            name="edit" />
                        <div class="label">
                            EDIT COLUMNS
                        </div>
                    </button>
                </styled-tooltip>
            </button>
        </div>
        <styled-dialog
            v-model="bulkActionConfirmationDialog"
            :width="700">
            <div class="bulk-action-dialog">
                <div
                    class="close-action"
                    @click="bulkActionConfirmationDialog = false">
                    <icon name="close" />
                </div>
                <h2 class="title">
                    Are you sure you want to run {{ selectedAction.label }}?
                </h2>

                <p class="message">
                    Once you confirm, {{ selectedAction.label }} will be executed on
                    <span class="bold-item">{{ selectedRows.length }} </span>
                    of <span class="bold-item">{{ totalItems }}</span> rows. Are
                    you sure you want to continue?
                </p>
                <styled-button
                    class="continue-button"
                    @click="triggerBulkAction(selectedAction.callback)">
                    YES, CONTINUE
                </styled-button>
                <p class="warning">
                    Warning: This action cannot be undone.
                </p>
            </div>
        </styled-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledButton from '@/components/globals/StyledButton';
import StyledDialog from '@/components/globals/StyledDialog';
import draggable from 'vuedraggable';

export default {
    name: 'FilterTableRow',
    components: {
        Icon,
        StyledTooltip,
        StyledButton,
        StyledDialog,
        draggable
    },
    props: {
        actions: {
            type: Array,
            default: () => [],
        },
        tableName: {
            type: String,
            required: true
        },
        searchLabel: {
            type: String,
            default: 'Search',
        },
        headers: {
            type: Array,
            required: true,
        },
        columnPresets: {
            type: Array,
            default: () => [],
        },
        hideSort: {
            type: Boolean,
            default: false,
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
        hasCustomization: {
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: '',
        },
        selectedRows: {
            type: Array,
            default: () => [],
        },
        pagination: {
            type: Object,
            default: () => {},
        },
        totalItems: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            showPresetColumnSelector: false,
            showPresetNameForm: false,
            showDeleteForm: false,
            columnsToShow: [],
            error: '',
            columnPresetName: null,
            nameRules: [(v) => !!v || 'Name is required'],
            columnPresetSelected: null,
            onCompletion: '',
            selectedAction: {
                label: '',
                showConfirmation: false,
                callback: () => {},
            },
            sortByData: [],
            searchString: '',
            bulkActionConfirmationDialog: false,
            sortableHeaders: [],
            personalPresetsLocal: [],
            agencyPresetsLocal: [],
            sharedSelected: false,
            currentPersonalPresets: [],
            currentAgencyPresets: [],
            loading: false,
            initialIDs: [],
            allowedToSave: false,
            tooltipPosition: 0
        };
    },
    computed: {
        ...mapState({
            personalPresets: (state) => state.settings.personalPresets,
            agencyPresets: (state) => state.settings.agencyPresets,
            agency: (state) => state.agency.currentAgency,
            user: (state) => state.user.user
        }),
        joinedPresets() {
            // Creating one array with all presets for v-select dropdown with 2 separate option groups
            const joinedPresets = [{ header: 'My Preset(s)' }];
            joinedPresets.push(...this.currentPersonalPresets);
            joinedPresets.push({ header: 'Shared Preset(s)' });
            joinedPresets.push(...this.currentAgencyPresets);
            return joinedPresets;
        }
    },
    watch: {
        showPresetColumnSelector() {
            if (this.showPresetColumnSelector) {
                const editButton = this.$refs.editButton;
                const editButtonRect = editButton.getBoundingClientRect();
                this.tooltipPosition = editButtonRect.top + document.documentElement.scrollTop;
            }
        },
        currentPersonalPresets(newPresets, previousPresets) {
            this.comparePresets(newPresets, previousPresets);
        },
        currentAgencyPresets(newPresets, previousPresets) {
            this.comparePresets(newPresets, previousPresets);
        },
        columnsToShow(value) {
            this.$emit('onUpdateColumnsPreset', value);
        },
        columnPresetSelected(value) {
            if (!value) {
                const headerIDs = this.initialIDs;
                this.sortableHeaders.sort(function(a, b) {
                    return (headerIDs.indexOf(a.id) >>> 0) - (headerIDs.indexOf(b.id) >>> 0);
                });
                this.sortableHeaders.forEach(header =>{
                    header.active = true;
                });
                if (this.sortableHeaders.length) {
                    this.columnsToShow = this.sortableHeaders;
                }
                localStorage.removeItem(this.tableName);
                this.hideNotDefaults();
            }
            this.sortByData = [];
            if (value) {
                this.columnPresetName = value.name;
            }
            if (this.columnsToShow.length) {
                this.vuetifySelectFix(this.columnsToShow);
            } else {
                this.vuetifySelectFix(this.headers);
            }
            this.$emit('onUpdateColumnsPreset', value?.columns || null);
        },
        selectedAction(value) {
            if (!value.label) return;
            const { showConfirmation } = value;
            if (showConfirmation) {
                this.bulkActionConfirmationDialog = true;
            } else {
                this.triggerBulkAction(value.callback);
            }
        },
        bulkActionConfirmationDialog() {
            if (this.bulkActionConfirmationDialog == false) {
                // clear just selected action from v-select to choose it once again
                this.selectedAction = {
                    label: '',
                    showConfirmation: false,
                    callback: () => {}
                };
            }
        },
        search(value) {
            // fixing bug when using global search together with single searches (across columns) at the same time
            if (value == '' || value == 'clear') {
                this.searchString = '';
            }
        },
        searchString(value) {
            this.$emit('onGlobalSearch', value);
        },
    },
    async created() {
        this.vuetifySelectFix(this.headers);
        this.headers.forEach(header => {
            this.initialIDs.push(header.id);
        });
        await this.getAllPresets();
        setTimeout(() => {
            const presetID = localStorage.getItem(this.tableName);
            let preset = null;
            this.currentPersonalPresets.forEach(p => {
                if (p.id == presetID) {
                    preset = p;
                }
            });
            this.currentAgencyPresets.forEach(p => {
                if (p.id == presetID) {
                    preset = p;
                }
            });
            if (preset) {
                this.columnPresetSelected = preset;
            } else {
                this.columnPresetSelected = null;
            }
        }, 0);
        setTimeout(() => {this.allowedToSave = true}, 100);
        this.hideNotDefaults();
    },
    methods: {
        hideNotDefaults() {
            this.sortableHeaders = this.headers;
            this.sortableHeaders.forEach(header => {
                if (header.default == false) {
                    header.active = false;
                } else {
                    header.active = true;
                }
            });
            this.columnsToShow = this.sortableHeaders.filter(header => header.active);
        },
        saveSelectedPreset(preset) {
            if (preset) {
                localStorage.setItem(this.tableName, preset.id);
            }
        },
        comparePresets(newPresets, previousPresets) {
            // set and select personal preset in the input after we create it
            if (previousPresets.length) {
                let newPreset;
                if (newPresets.length != previousPresets.length) {
                    // if we add new just check by id
                    newPreset = newPresets.filter(function(o1) {
                        return !previousPresets.some(function(o2) {
                            return o1.id === o2.id;
                        });
                    });
                } else {
                    // if we editing existing - check name and columns because they are editable
                    newPreset = newPresets.filter(function(o1) {
                        return !previousPresets.some(function(o2) {
                            return (o1.id === o2.id) && (o1.name === o2.name) && (JSON.stringify(o1.columns) === JSON.stringify(o2.columns)); // return the ones with equal id
                        });
                    });
                }
                this.columnPresetSelected = newPreset[0];
                if (this.columnPresetSelected && this.allowedToSave) {
                    localStorage.setItem(this.tableName, newPreset[0].id);
                }
            } else if (newPresets.length == 0) {
                return;
            } else if (newPresets.length == 1) {
                this.columnPresetSelected = newPresets[0];
                if (this.columnPresetSelected && this.allowedToSave) {
                    localStorage.setItem(this.tableName, newPresets[0].id);
                }
            }
        },
        formatName(name) {
            if (typeof name == 'object') {
                return name.top + ' / ' + name.bottom;
            } else {
                return name;
            }
        },
        checkSwitch() {
            const activeHeaders = [];
            this.sortableHeaders.forEach(header =>{
                if (header.active) {
                    activeHeaders.push(header);
                }
            });
            this.columnsToShow = activeHeaders;
        },
        onDraggingEnd() {
            const activeHeaders = [];
            this.sortableHeaders.forEach(header =>{
                if (header.active) {
                    activeHeaders.push(header);
                }
            });
            this.columnsToShow = activeHeaders;
        },
        vuetifySelectFix(array) {
            // fixing bug with VUETIFY v-select (it is not able to read data if key named 'header'... so we need to change it)
            array.forEach(h => {
                if (h.sortable) {
                    this.sortByData.push({ ...h });
                }
            });
            this.sortByData.forEach(i => {
                i.display_name = i.header;
                delete i.header;
            });
        },
        editColumnsPreset() {
            /*if(!this.sortableHeaders.length) {
                this.sortableHeaders = this.headers;
                this.sortableHeaders.forEach(header => {
                    header.active = true;
                });
            }*/
            this.showPresetColumnSelector = true;
            if (this.columnPresetSelected) {
                const columnIDs = [];
                this.columnPresetSelected.columns.forEach(column => {
                    columnIDs.push(column.id);
                });
                this.sortableHeaders.sort(function(a, b) {
                    return (columnIDs.indexOf(a.id) >>> 0) - (columnIDs.indexOf(b.id) >>> 0);
                });
                this.columnPresetName = this.columnPresetSelected.name;
                this.columnsToShow = this.columnPresetSelected.columns;

                this.sortableHeaders.forEach(header =>{
                    header.active = false;
                    this.columnPresetSelected.columns.forEach(column =>{
                        if (column.id == header.id) {
                            header.active = true;
                        }
                    });
                });
            } else {
                this.columnsToShow = this.sortableHeaders.filter(header => header.active);
            }
        },
        disableItem() {
            return this.selectedRows.length ? false : true;
        },
        setColumns() {
            this.onCompletion = null;
            if (!this.columnsToShow.length) {
                alert('Select at least one column');
            } else {
                this.columnPresetName = '';
                this.showPresetColumnSelector = false;
                this.showPresetNameForm = true;
            }
        },
        triggerBulkAction(callback) {
            this.bulkActionConfirmationDialog = false;
            if (typeof callback === 'function') {
                callback(this.selectedRows);
                // clear just selected action from v-select to choose it once again
                this.selectedAction = {
                    label: '',
                    showConfirmation: false,
                    callback: () => {}
                };
            }
        },
        setSorting(value) {
            this.$emit('sortColumn', value);
        },
        goBack() {
            this.showPresetNameForm = false;
            this.showDeleteForm = false;
            this.showPresetColumnSelector = true;
            if (this.columnPresetSelected) {
                this.columnPresetName = this.columnPresetSelected.name;
            }
        },
        async saveColumnPreset() {
            this.loading = true;
            await this.createPersonalPreset();
            if (this.sharedSelected) {
                await this.createSharedPreset();
            }
            this.loading = false;
            this.showPresetNameForm = false;
            await this.getAllPresets();
        },
        async reviseColumnPreset() {
            const preset = {
                name: this.columnPresetName,
                id: this.columnPresetSelected.id,
                columns: this.columnsToShow,
                shared: this.columnPresetSelected.shared
            };
            this.loading = true;
            await this.updatePreset(preset);
            this.showPresetNameForm = false;
            this.showPresetColumnSelector = false;
            this.loading = false;
            await this.getAllPresets(this.columnPresetSelected.shared);
        },
        async deletePreset() {
            const preset = {
                id: this.columnPresetSelected.id,
            };
            this.loading = true;
            await this.deletePresetRequest(preset);
            this.loading = false;
            this.showDeleteForm = false;
            await this.getAllPresets();
            localStorage.removeItem(this.tableName);
        },
        showDeleteConfirmation() {
            this.showPresetColumnSelector = false;
            this.showDeleteForm = true;
        },
        //api connection part - to do - clean and optimize
        async getUUID() {
            let uuid = '';
            try {
                const response = await this.$http.get('/uuid/');
                uuid = response.data.uuid4;
                return uuid;
            } catch(error) {
                console.log(error);
                let notification = {
                    response: 'error',
                    message: error
                };
                this.$emit('show-notification', notification);
            }
        },
        async createPersonalPreset() {
            const uuid = await this.getUUID();
            const personalPreset = {
                agency_id: this.agency.id,
                user_id: this.user.id,
                key: `custom_columns:${this.tableName}:${uuid}`,
                data: {
                    name: this.columnPresetName,
                    id: uuid,
                    shared: false,
                    columns: this.columnsToShow
                }
            };
            try {
                const response = await this.$http.post('/data-storage/', personalPreset);
                console.log(response);
                let notification = {
                    response: 'info',
                    message: 'New Preset was saved successfully!'
                };
                this.$emit('show-notification', notification);
            } catch(error) {
                console.log(error);
                let notification = {
                    response: 'error',
                    message: error
                };
                this.$emit('show-notification', notification);
            }
        },
        async createSharedPreset() {
            const uuid = await this.getUUID();
            const sharedPreset = {
                agency_id: this.agency.id,
                user_id: null,
                key: `custom_columns:${this.tableName}:${uuid}`,
                data: {
                    name: this.columnPresetName,
                    id: uuid,
                    shared: true,
                    columns: this.columnsToShow
                }
            };
            try {
                const response = await this.$http.post('/data-storage/', sharedPreset);
                console.log(response);
            } catch(error) {
                console.log(error);
                let notification = {
                    response: 'error',
                    message: error
                };
                this.$emit('show-notification', notification);
            }
        },
        async updatePreset(preset) {
            const data = {
                data: {
                    name: preset.name,
                    id: preset.id,
                    columns: preset.columns,
                    shared: preset.shared
                }
            };
            try {
                const response = await this.$http.patch(`/data-storage/custom_columns:${this.tableName}:${preset.id}`, data);
                console.log(response);
                let notification = {
                    response: 'info',
                    message: 'Preset was updated successfully!'
                };
                this.$emit('show-notification', notification);
            } catch(error) {
                console.log(error);
                let notification = {
                    response: 'error',
                    message: error
                };
                this.$emit('show-notification', notification);
            }
        },
        async deletePresetRequest(preset) {
            try {
                const response = await this.$http.delete(`/data-storage/custom_columns:${this.tableName}:${preset.id}`);
                console.log(response);
                let notification = {
                    response: 'info',
                    message: 'Preset was deleted successfully!'
                };
                this.$emit('show-notification', notification);
            } catch(error) {
                console.log(error);
                let notification = {
                    response: 'error',
                    message: error
                };
                this.$emit('show-notification', notification);
            }
        },
        async getAllPresets(sharedWasEdited) {
            this.loading = true;
            try {
                const response = await this.$http.get(`/data-storage?agency_id=${this.agency.id}&prefix=custom_columns:${this.tableName}:`);
                const allPresets = response.data.data;
                const personalPresets = [];
                const agencyPresets = [];
                allPresets.forEach(preset =>{
                    if (preset.user_id) {
                        if (this.user.id == preset.user_id) {
                            personalPresets.push(preset.data);
                        }
                    } else {
                        agencyPresets.push(preset.data);
                    }
                });
                // order matters because 'watch' triggered for both types of presets,
                // we need correct order depending what type we currently editing
                if (!sharedWasEdited) {
                    this.currentAgencyPresets = agencyPresets;
                    setTimeout(() => {this.currentPersonalPresets = personalPresets}, 0); //set delay to trigger 'watch' later
                } else {
                    this.currentPersonalPresets = personalPresets;
                    this.currentAgencyPresets = agencyPresets;
                }
                this.loading = false;
            } catch(error) {
                console.log(error);
                let notification = {
                    response: 'error',
                    message: error
                };
                this.$emit('show-notification', notification);
            }
        }
    },
};
</script>

<style lang="scss">
.table-header .column-presets {
    min-width: 220px;
    .v-text-field--prefix .v-text-field__prefix{
        opacity: 1;
        white-space: nowrap;
    }
    .v-select__selections > input{
        width: 1px;
    }
    .v-list__tile{
        height: 35px;
    }
}
.preset-name-tooltip{
    transform: translate(-30px, 0);
}
.theme--light.v-subheader{
    color:#4E555B;
    position: relative;
    height: auto;
    padding-left: 40px;
    margin-top:10px;
    padding-top: 10px;
    margin-bottom: 5px;
    border-top: 1px solid $gray-light;
    &:before{
        content: '';
        position: absolute;
        width: 17px;
        height: 17px;
        top:11px;
        left:13px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('/img/people.svg');
    }
    & ~ div[role=listitem] a{
        padding-left: 40px;
    }
    &:nth-of-type(1){
        border-top: 0;
        padding-top:5px;
        &:before{
            top: 8px;
            height: 15px;
            background-image: url('/img/user.png');
        }
    }
}
</style>
<style lang="scss" scoped>
.filter-row-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    &.sticky {
        position: fixed;
        z-index: 999;
        top: 65px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: white;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        .search {
            transform: scale(0.8) translateX(-30px);
        }
    }
    .sorting-fields {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .field {
        margin-right: 20px;
    }
    .sort-by {
        width: 175px;
    }
    .edit-columns{
        margin-top: 0px;
        & > div:nth-of-type(1),
        & > div:nth-of-type(2){
            height: 0;
        }
        div:nth-of-type(3){
            margin-top:3px;
        }
    }
    .edit-columns-button {
        align-self: flex-end;
        .label {
            margin-top: 5px;
            white-space: nowrap;
            font-size: 9px;
            font-weight: 400;
        }
    }
}
.basket-holder{
    display: flex;
    align-items: center;
    button{
        margin-bottom:15px;
        margin-left: 10px;
    }
}
.draggable-holder{
    max-height: 40vh;
    overflow: auto;
    text-align: left;
}
.customize-text{
    margin-bottom: 15px;
    font-size: 14px;
}
.delete-text{
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}
.delete-buttons{
    font-size: 16px;
    text-align: center;
}
.cancel{
    margin-top: 15px;
    margin-bottom: 15px;
    color: red;
    cursor: pointer;
}
.column-selection {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.column-selector {
    background: white;
    .column-selector-holder{
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        height: 40px;
        .dots{
            width: 20px;
            height: 20px;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            margin-top: -3px;
            margin-right: 3px;
            cursor: grab;
            div{
                width: 4px;
                height: 4px;
                background: #909FA8;
                margin: 0 1.5px;
            }
        }
    }
}
.actions {
    padding-top: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        width: calc(100% + 40px);
        height: 1px;
        background: $gray-light;
        top:0;
        left:-20px;
        right:0;
    }
    .small {
        margin: 0 5px;
        font-weight: bold;
        white-space: nowrap;
    }

}
.new-preset-form {
    .new-preset-buttons {
        margin-top: 15px;
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            width: calc(100% + 40px);
            height: 1px;
            background: $gray-light;
            top:0;
            left:-20px;
            right:0;
        }
    }
}
.bulk-action-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 45px 30px;
    .close-action {
        position: absolute;
        top: -25px;
        right: -25px;
        cursor: pointer;
    }
    .title {
        color: $blue-primary;
        font-weight: 400;
    }
    .continue-button {
        margin-top: 15px;
    }
    .message {
        margin-top: 30px;
        text-align: center;
        .bold-item {
            font-weight: 700;
        }
    }
    .warning {
        color: $carnation;
        font-weight: 600;
        margin-top: 30px;
        background-color: transparent !important;
    }
}
</style>
