<template>
    <svg
        id="L4"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve">
        <circle
            fill="#fff"
            stroke="none"
            cx="6"
            cy="50"
            r="6">
            <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1" />
        </circle>
        <circle
            fill="#fff"
            stroke="none"
            cx="26"
            cy="50"
            r="6">
            <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2" />
        </circle>
        <circle
            fill="#fff"
            stroke="none"
            cx="46"
            cy="50"
            r="6">
            <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3" />
        </circle>
    </svg>
</template>

<script>
export default {
    name: 'LoadingIcon',
};
</script>

<style lang="scss" scoped>
svg {
    width: 100px;
    height: 100px;
    margin: 20px;
    display: inline-block;
}
</style>
